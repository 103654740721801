<template>
  <div>
    <b-card-code
      title="Update Attribute Translation"
      :before-change="validationForm"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- locale -->
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Locale"
                rules="required"
              >
                <b-form-group
                  label="Locale"
                  label-for="locale"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="locale"
                    v-model="localeSelect"
                    :state="localeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="localeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Name -->
            <b-col md="4">
              <b-form-group label="Name" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="data.name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
export default {
  components: {
    BCardCode,

  },

  mixins: [heightTransition],
  props: ['tranLocale', 'tranName', 'tranID'],
  data() {
    return {
      id: 0,
      showSpinnerLoad: false,
      localeSelect: {
        text: '',
        value: '',
      },
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      data: {
        name: null,
        locale: '',
      },
      errors_back: '',
      showDismissibleAlert: false,
      required,
    }
  },

  created() {
    //  fetch data of attribute
    this.id = this.$route.params.id

    axios
      .get('attributes/translations/' + this.id + '/show')
      .then((result) => {
        const data = result.data.data
        if (data.locale == 'en') {
          ;(this.localeSelect.value = 'en'),
            (this.localeSelect.text = 'English')
        } else {
          ;(this.localeSelect.value = 'ar'), (this.localeSelect.text = 'Arabic')
        }
        this.data.name = data.name
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },

  methods: {
    // submit update data of attribute API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.locale = this.localeSelect.value

          axios
            .put('attributes/translations/' + this.id + '/update', this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
​
<style lang="scss">

</style>
​
<style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  transition: 0.35s height;
}
</style>